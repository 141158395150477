.image-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.about-us-text {
  position: absolute;
  top: 100px;
  left: 41%;
  transform: translateX(-50%);
  font-size: 3.5rem !important;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
}

.image-wrapper {
  flex: 1;
  height: 100%;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-1 {
  --initial-x: 0;
  --initial-y: -100%;
  --animate-x: 0;
  --animate-y: 0;
}

.image-2 {
  --initial-x: 0;
  --initial-y: 100%;
  --animate-x: 0;
  --animate-y: 0;
}

.image-3 {
  --initial-x: 0;
  --initial-y: -100%;
  --animate-x: 0;
  --animate-y: 0;
}

@media screen and (max-width: 1024px) {
  .image-container {
    flex-direction: column;
  }

  .about-us-text {
    left: 35%;
  }

  .image-wrapper {
    width: 100%;
    height: 33.33vh;
  }

  .image-1 {
    --initial-x: -100%;
    --initial-y: 0;
    --animate-x: 0;
    --animate-y: 0;
  }

  .image-2 {
    --initial-x: 100%;
    --initial-y: 0;
    --animate-x: 0;
    --animate-y: 0;
  }

  .image-3 {
    --initial-x: -100%;
    --initial-y: 0;
    --animate-x: 0;
    --animate-y: 0;
  }
}

@media screen and (max-width: 480px) {
  .image-container {
    flex-direction: column;
  }

  .about-us-text {
    left: 20%;
  }

  .image-wrapper {
    width: 100%;
    height: 33.33vh;
  }

  .image-1 {
    --initial-y: 0;
    --initial-x: -100%;
    --animate-y: 0;
    --animate-x: 0;
  }

  .image-2 {
    --initial-y: 0;
    --initial-x: 100%;
    --animate-y: 0;
    --animate-x: 0;
  }

  .image-3 {
    --initial-y: 0;
    --initial-x: -100%;
    --animate-y: 0;
    --animate-x: 0;
  }
}