.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #8F897D;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 1s ease-out;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.welcome-image {
  max-width: 30vw;
  height: auto;
  max-height: 70vh;
  border-radius: 1rem;
}

.video-container {
  width: calc(50% - 0px);
  height: 80%;
  box-sizing: border-box;
  border: 0;
  background: #8F897D;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-element {
  width: calc(50% - 2px);
  height: 80%;
  border: none;
  background: #8F897D;
  object-fit: cover;

}



.card-container {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  z-index: 3;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .card-container {
    display: none;
  }

  .card-phone {
    border-radius: 0;
    background-color: var(--blue);
  }

  .no-phone {
    display: none;
  }

  .how-it-works-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .how-it-works-container>div {
    width: 100%;
    margin-bottom: 2rem;
  }

  .how-it-works-container>div:last-child {
    margin-bottom: 0;
  }

  .how-it-works-container img {
    width: 100%;
    max-width: 300px;
    height: auto;
  }

  .welcome-image {
    max-width: 90vw;
    max-height: 60vh;
  }

  .welcome-container {
    justify-content: center;
  }
}

@media screen and (min-width: 768px) and (max-width:840px) {
  .howItWorksContainer {
    max-width: 23.3%;
  }

  .welcome-image {
    max-width: 40vw;
    max-height: 50vh;
    margin: auto;
  }
}

@media screen and (min-width: 840px) {
  .howItWorksContainer {
    max-width: 23.3%;
    margin: auto;
  }
}

@media screen and (min-width: 768px) {

  .howIW-image {
    max-width: 100%;
    border-radius: 0.75rem;
  }

}


@media screen and (max-width: 480px) {
  .beautify-space-container {
    flex-direction: column-reverse !important;
  }

  .beautify-space-container>div {
    width: 100%;
    max-width: 100%;
    padding: 1rem 0;
  }

  .beautify-space-container .image-container {
    margin-bottom: 1rem;
  }

  .beautify-space-container .image-container img {
    width: 100%;
    height: auto;
  }

  .beautify-space-container .text-container {
    text-align: center;
  }

  .beautify-space-container .flex.align-items-center {
    justify-content: center !important;
  }



  .inspiration-collection .mobile-slider {
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
  }

  .inspiration-collection .slider-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .inspiration-collection .slide {
    flex: 0 0 100%;
    width: 100%;
  }

  .inspiration-collection .slide img {
    width: 100%;
    height: auto;
    display: block;
  }

  .inspiration-collection .slider-nav {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .inspiration-collection .slider-nav button {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 18px;
    cursor: pointer;
    z-index: 10;
    pointer-events: auto;
  }

  .custom-md-start {
    justify-content: center !important;
    align-items: center;
  }

  .production-image {
    max-width: 90vw;
    height: auto;
    align-self: center;
    border-radius: 1rem;
  }

  .production-image-container {
    height: 60vh;
    margin: auto;
  }
}



@media screen and (min-width: 481px) and (max-width: 768px) {

  .card-container {
    position: absolute;
    top: 45%;
    left: 2vw;
    z-index: 0;
    width: 100%;
    opacity: 0.98;
  }

  .tablet {
    height: 15vh;
    color: var(--blue);
  }

  .mini {
    margin-bottom: 10px;
  }

  .card-phone {
    display: none;
  }

  .beautify-space-container .flex.align-items-center {
    justify-content: center;
  }

  .bg-blue {
    padding: 2rem 0.5rem;
  }

  .bg-blue .flex {
    gap: 2rem;
  }

  .bg-blue .flex>div {
    margin-top: 0 !important;
  }

  .discover {
    width: 50%;
  }


}

@media screen and (min-width: 768px) {

  .card-container {
    position: absolute;
    top: 41%;
    left: 2vw;
    z-index: 3;
    width: 100%;
    opacity: 0.98;
  }

  .card-phone {
    display: none;
  }
  .tablet {
    height: 15vh;
    color: var(--blue);
  }

  .mini {
    margin-bottom: 48px;
  }


  .beautify-space-container {
    flex-direction: column-reverse !important;
  }

  .beautify-space-container>div {
    width: 100%;
    max-width: 100%;
    padding: 1rem 0;
  }

  .beautify-space-container .image-container {
    margin-bottom: 1rem;
  }

  .beautify-space-container .image-container img {
    width: 100%;
    height: auto;
  }

  .beautify-space-container .text-container {
    text-align: center;
  }

  .beautify-space-container .flex.align-items-center {
    justify-content: center !important;
  }

  .production-image {
    max-width: 30vw;
    height: auto;
    margin: auto;
    align-self: center;
    border-radius: 1rem;
  }
}

@media screen and (min-width: 1025px) {

  .card-phone {
    display: none;
  }

  .mini {
    margin-bottom: 48px;
  }


}