.bg-blue {
    background-color: var(--blue);
}

.bg-golden {
    background-image: linear-gradient(to bottom, var(--dark-golden), var(--light-golden));
}

.bg-dark-golden {
    background-color: var(--dark-golden);
}

.bg-light-golden {
    background-color: var(--light-golden);
}

.bg-white {
    background-color: var(--white);
}

.bg-black {
    background-color: var(--black);
}

.text-blue {
    color: var(--blue);
}

.text-golden {
    color: var(--dark-golden);
}

.text-white {
    color: var(--white);
}

.text-black {
    color: var(--black);
}

