* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.p-card {
    background-image: linear-gradient(to bottom, var(--dark-golden), var(--light-golden));
    color: var(--black);
    box-shadow: none;
    border-radius: 0.5rem;
}

.p-card .p-card-body {
    padding: 2rem;
}

.p-card .p-card-content {
    padding: 0rem;
}

.p-button {
    padding: 1rem 2.25rem;
    font-weight: 700;
    border-radius: 3rem;
    border: none;
}

.p-inputtext, .p-inputtext:focus {
    border-radius: 0.5rem;
    padding: 1rem;
    background-color: var(--blue);
    box-shadow: none;
    color: var(--white);
}

.p-inputtext:focus {
    border: 1px solid var(--blue);
    color: var(--blue);
    background-color: var(--white);
}


@media (max-width: 576px) {
    .p-card .p-card-body {
        padding: 1rem;
    }

    .p-button {
        padding: 0.75rem 1.5rem;
    }
}


.p-sidebar {
    height: 100vh !important;
     background-color: rgba(0, 0, 0, 0.7); 
} 


.p-sidebar .sidebar-header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: var(--white);
}

.p-button.p-button-text {
    font-weight: bold;
    color: var(--white);
}

.p-button.p-button-text:hover {
    background-color: rgba(255, 255, 255, 0.1);
} 



.joiner
{
    width: "100%";
    margin: 5vh;
    background-color: (--light-golden) ;
    z-index: 0;
}


