@media screen and (min-width:900px) {
	.till-tabs {
		display: none !important;
	}
}

@media screen and (max-width:900px) and (min-width:500px) {
	.no-tab {
		display: none;
	}
}

@media (max-width:500px), (min-width:900px) {
	.tabs {
		display: none !important;
	}
}

.trans3d {
	-webkit-transform-style: preserve-3d;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform-style: preserve-3d;
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform-style: preserve-3d;
	-ms-transform: translate3d(0, 0, 0);
	transform-style: preserve-3d;
	transform: translate3d(0, 0, 0);

	max-height: 60vh;
	max-width: 100vw;
	height: max-content;
	align-content: flex-start;
}

#contentContainer {
	max-width: 90vw;
	height: 90vh;
	margin: auto;
}

#carouselContainer {
	position: absolute;
	margin: auto;
	width: 100vw;
	height: 100vh;

}

.carouselItem {
	width: auto;
	height: auto;
	position: absolute;
	left: 50%;
	top: 0%;
}

.carouselItemInner {
	max-width: 450px;
	margin: 200px;
	height: auto;
	position: absolute;

	transform: scaleX(-1);
	font-size: 72px;
	left: 50%;
	top: 5vh;
	margin-left: -160px;
	margin-top: 0vh;
	text-align: center;
}

.carouselItemInner2 {
	width: 450px;
	margin: 200px;
	height: auto;
	position: absolute;

	transform: scaleX(-1);
	font-size: 35px;
	left: 50%;
	top: 20vh;
	margin-left: -160px;

}

.carouselItemInner:hover {
	zoom: 1.1;
}