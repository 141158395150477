/* About us components*/

/* Base styles */
.about-image {
    width: 100%;
    height: auto;
    max-height: 30rem;
    border-radius: 1.5rem; /* equivalent to border-round-3xl */
    object-fit: cover;
  }
  
  .team-image {
    width: 100%;
    height: auto;
    border-radius: 1rem; /* equivalent to border-round-2xl */
    object-fit: cover;
  }
  
  /* Mobile devices (320px — 480px) */
  @media screen and (max-width: 480px) {
    /* No changes needed as base styles work well for mobile */
    .team-image {
      width: 80vw;
      height: auto;
    }
  }
  
  /* iPads, Tablets (481px — 768px) */
  @media screen and (min-width: 481px) and (max-width: 768px) {
     
    .team-image {
      width: 250px;
      height: 250px;
    }
  }
  
  /* Small screens, laptops (769px — 1024px) */
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .about-image {
      width: 50%;
      height: auto;
    }
    
    .team-image {
      width: 300px;
      height: 300px;
    }
  }
  
  /* Desktops, large screens (1025px and above) */
  @media screen and (min-width: 1025px) {
    .about-image {
      width: 30rem;
      height: auto;
    }
  
    .team-image {
      width: 30rem;
      height: 35rem;
    }
  }
