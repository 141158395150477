body {
  margin: 0px;
  --blue: #0B3542;
  --dark-golden: #B3A379;
  --light-golden: #F5E8D0;
  --white: #F3F3F3;
  --black: #333333;
}
@font-face {
  font-family:Century Schoolbook;
  src: url('./Assets/fonts/century-schoolbook/SCHLBKB.TTF');
}
@font-face {
  font-family: Helvetica;
  src: url('./Assets/fonts/helvetica-255/Helvetica.ttf');
}
.heading{
  font-optical-sizing: auto;
  font-family: Century Schoolbook,serif;

  font-weight: bold;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
.p-component{
  font-style: normal;
  font-family: Helvetica, sans-serif;

  font-weight: 400 !important;
}
.our-team-background{
  background-position: center;
  width: 90vw;
  height:auto;
  border-radius: 1rem;
}
@media screen and (max-width: 768px) {
  .our-team-background{
    width: 80vw;
  }
}
.our-team-background-container{
  display: flex;
  flex-direction:column;
  width: 100vw;
  height: max-content;
  align-items: center;
}
.contact-us-background {
  background-image: url("./Assets/Contact\ Us.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

