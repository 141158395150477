

.collage-item {
    position: relative;
    overflow: hidden;
}

.collage-item::before {
    content: '';
    display: block;
    padding-top: 100%;
}

.collage-item > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.collage-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 10vw;
    gap: 10px;
}


.collage-item:nth-child(1) { grid-column: span 2; grid-row: span 2; }
.collage-item:nth-child(2) { grid-column: span 1; grid-row: span 2; }
.collage-item:nth-child(3) { grid-column: span 1; grid-row: span 2; }
.collage-item:nth-child(4) { grid-column: span 2; grid-row: span 2; }
.collage-item:nth-child(5) { grid-column: span 1; grid-row: span 2; }
.collage-item:nth-child(6) { grid-column: span 2; grid-row: span 2; }
.collage-item:nth-child(7) { grid-column: span 1; grid-row: span 2; }
.collage-item:nth-child(8) { grid-column: span 2; grid-row: span 2; }



@media screen and (min-width: 769px) and (max-width: 1024px) {
    .collage-grid {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 150px;
    }

    .collage-item:nth-child(1) { grid-column: span 2; grid-row: span 2; }
    .collage-item:nth-child(2) { grid-column: span 1; grid-row: span 1; }
    .collage-item:nth-child(3) { grid-column: span 1; grid-row: span 2; }
    .collage-item:nth-child(4) { grid-column: span 2; grid-row: span 2; }
    .collage-item:nth-child(5) { grid-column: span 1; grid-row: span 2; }
    .collage-item:nth-child(6) { grid-column: span 1; grid-row: span 2; }
    .collage-item:nth-child(7) { grid-column: span 1; grid-row: span 1; }
    .collage-item:nth-child(8) { grid-column: span 2; grid-row: span 1; }


}

@media (max-width: 480px) {
    .collage-grid {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 100px;
    }

    .collage-item:nth-child(1) { grid-column: span 2; grid-row: span 2; }
    .collage-item:nth-child(2) { grid-column: span 1; grid-row: span 1; }
    .collage-item:nth-child(3) { grid-column: span 1; grid-row: span 2; }
    .collage-item:nth-child(4) { grid-column: span 2; grid-row: span 2; }
    .collage-item:nth-child(5) { grid-column: span 1; grid-row: span 2; }
    .collage-item:nth-child(6) { grid-column: span 1; grid-row: span 2; }
    .collage-item:nth-child(7) { grid-column: span 1; grid-row: span 1; }
    .collage-item:nth-child(8) { grid-column: span 2; grid-row: span 1; }
 

}


