
body {
	overflow-x:hidden;
}
.races {
	width:fit-content;
	display:flex;
	flex-wrap:nowrap;
      padding-left:50vw;
      padding-right:40vw;
}
.races > div > div:nth-child(2){ 
	margin-left:20vw;	
	margin-right:20vw;	
}
.gallory-divider{
      height: 20vh;
}
.custom-image{
	border-radius: 15px;
	max-height: 400px;	
	@media (max-width:425px) {
		max-width: 90vw;
		max-height: 70vh;
	};
}
.imag:hover{
	transform:scale(1.1);
	transition-duration:1000;
	transition-delay: 10;
}
