  .contact-container {
    display: flex;
    justify-content: space-around;
    gap: 2rem;
    padding: 1.5rem;
    align-items: flex-start;
  }

  .contact-us-background2 {
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url("../Assets/Contact\ Us.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 50vh;
  }

  .contact-form {
    width: 100%;
    max-width: 30rem;
  }

  .contact-info {
    width: 100%;
    max-width: 30rem;
  }

  .map-container {
    width: 100%;
    height: 400px;
  }

  @media screen and (max-width: 480px) {
    .contact-container {
      flex-direction: column;
      gap: 1rem;
    }

    .contact-us-background2 {
      height: 30vh;
    }

    .contact-form,
    .contact-info {
      max-width: 100%;
    }

    .map-container {
      height: 300px;
    }
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    .contact-container {
      flex-direction: column;
      gap: 2rem;
    }

    .contact-form,
    .contact-info {
      max-width: 100%;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .contact-container {
      flex-direction: column;
      gap: 2rem;
      align-items: center;
    }

    .contact-form,
    .contact-info {
      align-items: center;
      max-width: 90%;
    }
  }


  @media screen and (min-width: 1025px) {
    .contact-form {
      margin-top: 10rem;
    }
  }