.fixed.w-full.z-5 > div {
    transition: background-color 0.3s ease;

    z-index: 20;

}

.menu-items {
    text-decoration: none;
    color: var(--white);
    font-weight: 500;
    font-size: 1.25rem;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.menu-items:hover {
    color: var(--dark-golden);
}

.fixed img {
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
}

@media (max-width: 768px) {
    .p-sidebar-content .menu-items {
        font-size: 1.5rem;
        padding: 1rem 0;
    }

    .fixed.w-full.z-5 > div {
        padding: 1rem 2rem;
    }
}

@media (max-width: 576px) {
    .p-card .p-card-body {
        padding: 1rem;
    }

    .p-button {
        padding: 0.75rem 1.5rem;
    }
}

.p-sidebar {
    height: 100vh !important;
     background-color: rgba(0, 0, 0, 0.7); 
}

.p-sidebar .sidebar-header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: var(--white);
}

.p-button.p-button-text {
    font-weight: bold;
    color: var(--white);
}

.p-button.p-button-text:hover {
    background-color: rgba(255, 255, 255, 0.1);
}


.dropdown-background {
    position: relative;
    display: inline-block;
}

.dropdown-background::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: 2rem;
    background-color: var(--blue);
    border-radius: 50%;
    z-index: -5;
}

